<template>
  <div
    id="cartao-wrapper"
    class="row justify-center q-pa-sm"
    style="position: relative; color: white"
  >
    <div class="row justify-center" style="position: relative; color: white">
      <template v-if="loadingCartao">
        <q-card style="width: 470px; height: 295px">
          <q-skeleton width="100%" height="100%" />
        </q-card>
      </template>
      <template v-else>
        <img
          :src="`cartao-${empresa}.png`"
          style="width: 100%; max-width: 470px; height: 100%"
        />
        <div class="text-subtitle2" :style="styleDadosCartao">
          <div class="row q-px-md line-height-none">
            {{ nomeCliente }}
          </div>
          <div :class="`row q-px-md line-height-none ${cardInfoSpace}`">
            CLIENTE DESDE: {{ dataCadastro }}
          </div>
          <div class="row q-px-md line-height-none">
            {{ numeroCartaoMascara }}
          </div>
          <div class="row justify-center q-mt-md">
            <vue-barcode :value="numeroCartaoDigital" :options="barCodeOptions" />
          </div>
        </div>
      </template>
    </div>
  </div>
  <div class="row flex flex-center">
    <template v-if="loadingCartao">
      <q-skeleton class="q-mt-sm" width="100%" height="30px" />
    </template>
    <template v-else>
      <q-btn
        v-if="mostrarBotaoCopiarNumero"
        color="green"
        :label="`Copiar número do cartão ${rede}`"
        icon="content_copy"
        id="btnCopiar"
        class="full-width q-mt-sm"
        style="max-width: 470px"
        v-clipboard:copy="numeroCartao"
        v-clipboard:success="successCopyNumeroCartao"
        v-clipboard:error="errorCopyNumeroCartao"
      />
      <q-btn
        color="green"
        label="Download do cartão digital - PNG"
        icon="download"
        class="full-width q-mt-sm"
        style="max-width: 470px"
        @click="downloadCartaoImagem"
      />
      <q-btn
        color="green"
        label="Download do cartão digital - PDF"
        icon="download"
        class="full-width q-mt-sm"
        style="max-width: 470px"
        @click="downloadCartaoPDF"
      />
    </template>
  </div>
</template>

<script>
import MixinMessages from "@/mixins/mixinMessages";
import html2pdf from "html2pdf.js";
import * as htmlToImage from "html-to-image";
import download from "downloadjs";

export default {
  name: "QCartaoDigital",

  mixins: [MixinMessages],

  props: {
    idCartao: {
      type: String,
      required: true,
    },
    loadingCartao: {
      type: Boolean,
      default: true,
    },
    empresa: {
      type: String,
      default: "",
    },
    nomeCliente: {
      type: String,
      default: "",
    },
    dataCadastro: {
      type: String,
      default: "",
    },
    numeroCartaoMascara: {
      type: String,
      default: "",
    },
    numeroCartao: {
      type: String,
      default: "",
    },
    mostrarBotaoCopiarNumero: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      barCodeOptions: {
        width:
          this.$q.screen.width >= 530
            ? 1.8
            : this.$q.screen.width >= 480 && this.$q.screen.width < 530
            ? 1.8
            : this.$q.screen.width >= 430 && this.$q.screen.width < 480
            ? 1.6
            : this.$q.screen.width >= 380 && this.$q.screen.width < 430
            ? 1.3
            : this.$q.screen.width >= 330 && this.$q.screen.width < 380
            ? 1.1
            : 0,
        height:
          this.$q.screen.width >= 530
            ? 40
            : this.$q.screen.width >= 480 && this.$q.screen.width < 530
            ? 50
            : this.$q.screen.width >= 430 && this.$q.screen.width < 480
            ? 40
            : this.$q.screen.width >= 380 && this.$q.screen.width < 430
            ? 30
            : this.$q.screen.width >= 330 && this.$q.screen.width < 380
            ? 20
            : 0,
        displayValue: false,
        background: "#FFF",
        lineColor: "#000",
        margin: 1
      },
    };
  },

  computed: {
    rede() {
      return process.env.VUE_APP_DESCRICAO_EMPRESA;
    },

    styleDadosCartao() {
      if (this.$q.screen.width >= 530) {
        return "position: absolute; bottom: 20px; font-size: 1em; width: 440px";
      } else if (this.$q.screen.width >= 480 && this.$q.screen.width < 530) {
        return "position: absolute; bottom: 20px; font-size: 1em; width: 420px;";
      } else if (this.$q.screen.width >= 430 && this.$q.screen.width < 580) {
        return "position: absolute; bottom: 15px; font-size: 1em; width: 370px;";
      } else if (this.$q.screen.width >= 380 && this.$q.screen.width < 430) {
        return "position: absolute; bottom: 15px; font-size: 0.9em; width: 330px;";
      } else if (this.$q.screen.width >= 330 && this.$q.screen.width < 380) {
        return "position: absolute; bottom: 15px; font-size: 0.85em; width: 290px;";
      }
      return "";
    },

    cardInfoSpace() {
      return this.$q.screen.width >= 380
        ? "q-py-sm"
        : this.$q.screen.width < 380
        ? "q-py-xs"
        : "";
    },

    numeroCartaoDigital() {
      return `c01=${this.numeroCartao}:`
    }
  },

  methods: {
    downloadCartaoPDF() {
      html2pdf(document.getElementById("cartao-wrapper"), {
        margin: 1,
        filename: `cartao-${String(this.rede).toLowerCase()}.pdf`,
      }).then(() => {
        this.successMessage({
          title: "Cartão Digital",
          message: "Download realizado com sucesso!",
        });
      });
    },

    downloadCartaoImagem() {
      htmlToImage
        .toPng(document.getElementById("cartao-wrapper"))
        .then((dataURL) => {
          download(dataURL, `cartao-${String(this.rede).toLowerCase()}.png`);
          this.successMessage({
            title: "Cartão Digital",
            message: "Download realizado com sucesso!",
          });
        })
        .catch(() => {
          this.errorMessage({
            title: "Ops, algo de errado aconteceu!",
            message: "Não foi possível realizar o download do cartão!",
          });
        });
    },

    successCopyNumeroCartao() {
      this.successMessage({
        title: "Cartão Digital",
        message: "O número do cartão foi copiado com sucesso!",
      });
    },

    errorCopyNumeroCartao() {
      this.errorMessage({
        title: "Ops, algo de errado aconteceu!",
        message: "Não foi possível copiar o número do cartão!",
      });
    },
  },
};
</script>
<style>
.line-height-none {
  line-height: 1;
}
</style>
